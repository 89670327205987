export const designStyleData = {
  "American": [
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/01.jpeg"
    }
  ],
  "European": [
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/01.jpeg"
    },
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/02.jpeg"
    }
  ],
  "Modern": [
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/01.jpeg"
    },
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/02.jpeg"
    }
  ],
  "Minialist": [
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/01.jpeg"
    }
  ],
  "Mid-Century Modern Stlye": [
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/01.jpeg"
    },
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/02.jpeg"
    }
  ],
  "Artsy": [
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/01.jpeg"
    },
    {
      "location": "https://designimages.sfo3.digitaloceanspaces.com/design1/02.jpeg"
    }
  ]
}